import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { getAPIVersion } from '../services/developer';

const Footer = (props) => {
    const { t } = useTranslation();
    const [version, setVersion] = useState('');

    useEffect(() => {
        const fetchVersion = async () => {
            try {
                const result = await getAPIVersion();
                setVersion(result.version); // Adjust based on your API response structure
            } catch (error) {
                console.error('Error fetching API version:', error);
            }
        };

        fetchVersion(); // Call the async function
    }, []);

    return (
        <React.Fragment>
            <footer className={props.styles}>
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <div className="text-sm-end d-sm-block">
                            {new Date().getFullYear()} © {t('Footer.CompanyName')} | {t('Footer.Description')} | v{version}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;